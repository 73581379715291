class window.ProspectSearch
  constructor: (@target) ->
    @el            = @target
    @formContainer = @target.find('.prospect-container')
    @results       = @target.find('.results')
    @successEl     = $('.row.prospect-success')

    @watchForm()

    @results.on 'click', '.selection', (e) =>
      e.preventDefault()
      target = $(e.currentTarget)
      data = target.data()

      dataCopy = $.extend({}, data);

      # hax for camel case stuff
      dataCopy['postal_code'] = dataCopy.postalCode
      dataCopy['infogroup_id'] = String(dataCopy.infogroupId)
      delete dataCopy.postalCode
      delete dataCopy.infogroupId

      $.get 'prospects/new', { "prospect": dataCopy }, @renderForm, 'html'

  watchForm: =>
    @formContainer.find('input[type="text"],input[type="tel"],select').on 'delayedChange', delay: 300, (e) =>
      @search(@formContainer.find('form').serializeArray())

    @formContainer.find('form').submit (e) =>
      e.preventDefault()
      @submit(@formContainer.find('form').serializeArray())

  submit: (formData) =>
    $.post 'prospects', formData, @success

  success: =>
    @el.slideUp 'slow', () =>
      @successEl.slideDown('slow')

  search: (form_data) ->
    form_values = {}
    for form_datum in form_data
      form_values[form_datum['name']] = form_datum['value']

    if form_values['prospect[name]'] || form_values['prospect[phone]']
      $.get('/search', form_data, @update, 'html')

  update: (data) =>
    @results.empty()
    @results.html(data)
    @results.find('.selection').hover(@addHover, @removeHover)

  addHover: ->
    $(this).addClass('text-white bg-secondary')

  removeHover: ->
    $(this).removeClass('text-white bg-secondary')

  renderForm: (formHtml) =>
    @formContainer.html(formHtml)
    @results.hide()
    @watchForm()
