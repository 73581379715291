# Fires an event after a text field's value remains static for 100ms
#
# $('input[type="text"]').on('delayedChange', function() {
#   alert('it finally changed!')
# })
#
(($) ->
  $.event.special.delayedChange =
    bindType: "input"
    delegateType: "input"
    handle: (e, data) ->
      delay = e.handleObj.data?.delay || 100
      el = $(this)
      original = el.val()

      setTimeout =>
        e.handleObj.handler.call(this, e, data) if original == el.val()
      , delay
)(jQuery)
