class window.ProspectIndex
  constructor: (@target) ->
    @el = @target
    @tableContainer = @el.find('.prospect-table')
    setInterval(@refreshProspects, 5000)

  refreshProspects: =>
    time = new Date().getTime() - 60000
    $.ajax
      type: "GET"
      url: "prospects"
      success: @success
      dataType: "html"

  success: (data) =>
    @tableContainer.html(data)
